import React from 'react'
// import logo from '../logo.svg'

function HeaderComponent() {
    return (
        <header className="App-header">
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <h3>5 Why's Tool</h3>
        </header>
    );
  }
  
  export default HeaderComponent;